import ApiClient from "../../../shared/api/ApiClient";

const getEtas = (): Promise<any> => ApiClient.get("/etas").then((res) => res.data);

const downloadLatestFile = (dcCode: string): Promise<any> =>
  ApiClient.get(`/etas/${dcCode}/download/latest`, {
    responseType: "blob",
  }).then((res) => res);

const downloadOriginalFile = (dcCode: string): Promise<any> =>
  ApiClient.get(`/etas/${dcCode}/download`, {
    responseType: "blob",
  }).then((res) => res);

const sendLineMessage = (dcCode: string): Promise<any> =>
  ApiClient.post(`/etas/${dcCode}/sendline`).then((res) => res.data);

const deleteEta = (dcCode: string): Promise<any> =>
  ApiClient.delete(`/etas/${dcCode}`).then((res) => res.data);

const uploadEta = (dcCode: string, originFileObj: File, v2: boolean): Promise<any> => {
  const formData = new FormData();
  formData.append("file", originFileObj);
  formData.append("dc_code", dcCode);
  const v2EndPoint = "/v2/delivery-orders/upload";
  return ApiClient.post(`${v2 ? v2EndPoint : "/etas/upload"}`, formData, { timeout: 3600000 }).then(
    (res) => res.data,
  );
};

const deliverOrderService = {
  getEtas,
  downloadLatestFile,
  downloadOriginalFile,
  sendLineMessage,
  deleteEta,
  uploadEta,
};

export default deliverOrderService;
